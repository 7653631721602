import "bootstrap/dist/css/bootstrap.min.css";
import Client from "fhir-kit-client";
import { Parameters } from "fhir/r5";
import i18n from "i18next";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import PandoraPage from "../../components/PandoraPage/PandoraPage";
import styles from "./validatorHome.module.css";

const ValidatorHome: FunctionComponent = () => {

  const { validatorParam } = useParams();

  const [resource, setResource] = useState("");
  const [profiles, setProfiles] = useState([] as { name: string, url: string }[]);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [file, setFile] = useState(null as File | null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fhirClient = new Client({
    baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
  });

  useEffect(() => {
    loadProfiles();
  }, []);

  /**
    * Load Profiles from the back to populate Validation report page.
    *
    * @returns the promise of a Structure Definition.
    */
  async function loadProfiles() {
    setLoading(true);
    try {
      const response = await fhirClient.operation({
        name: '$profiles',
        method: 'GET',
        input: { "validatorId": validatorParam}
      })
      const results: Parameters = response as Parameters;
      console.log(JSON.stringify(results))
      const profiles: { name: string; url: string; }[] = [];
      results.parameter?.forEach(param => {
        profiles.push({ name: param.name, url: param.valueString ?? '' });
      })
      setProfiles(profiles);
      setLoading(false);
    } catch (error) {
      console.log(error);
      onError();
    }
  }

  const onError = useCallback(() => {
    navigate("/Error");
  }, [navigate]);

  /**
    * Redirect to the report page.
    *
    * @param id ID of the Report to display.Added 'Main' component on TestReports page
    */
  const toValidationReport = useCallback((id: string) => {
    navigate("/ValidationReport/" + id);
  }, [navigate]);

  /**
    * Send validation to populate the Validation report page.
    */
  function sendToValidation() {
    setLoading(false);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/fhir+json');
    buildValidationParametersAndSend();
    setLoading(true);
  }

  /**
    * Build validation parameters and send to populate the Validation report page.
    */
  function buildValidationParametersAndSend() {
    const parameters: Parameters = { resourceType: "Parameters" } as Parameters;
    parameters.parameter = [];

    parameters.parameter.push({ name: "validatorId", valueString: validatorParam ? validatorParam : "" });

    if (selectedProfile.length !== 0) {
      parameters.parameter.push({ name: "profile", valueCanonical: selectedProfile })
    }
    if (resource.length !== 0) {
      parameters.parameter.push({ name: "resource", valueString: resource })
      callValidation(parameters)
    } else if (file !== null) {
      const reader = new FileReader()
      reader.onload = async (e) => {
        const text = (e.target?.result);
        parameters?.parameter?.push({ name: "resource", valueString: text ? text as string : "" });
        callValidation(parameters);
      };
      reader.readAsText(file);
    }
  }

  /**
     * Call validation from the back to populate the Validation report page.
     *
     * @param parameters    the parameters to the Structure Definition.
     */
  function callValidation(parameters: Parameters) {
    fhirClient.operation({
      name: "validate",
      method: 'POST',
      input: JSON.stringify(parameters)
    })
      .then(report => toValidationReport(report.id))
      .catch(error => {
        console.error(error)
        onError();
      });
  }

  /**
  * Get Profiles option to populate the select.
  *
  * @param name   the name to the Structure Definition.
  * @param url    the url to the Structure Definition.
  */
  function getProfileOptions(profile: { name: string, url: string }) {
    return <option value={profile.url}>{profile.name + " (" + profile.url + ")"}</option>;
  }

  /**
    * Reset the values of the resource, the resource of the file and the option of the select.
    */
  function onReset() {
    setLoading(true);
    setResource('');
    setSelectedProfile('');
    setLoading(false);
    const input = document.getElementById('inputFile') as HTMLInputElement;
    input.value = '';
  }

  return (
    <PandoraPage titleKey='title.validator' loading={loading} needsLogin={false} >
      <div className={styles.validatorHome}>
        <Form.Group>
          <Form.Label>
            {i18n.t('validator.labels.fhirResource')}
          </Form.Label>
          <Form.Control
            as="textarea"
            value={resource}
            rows={5}
            placeholder={i18n.t('validator.textarea.placeHolder').trimEnd()}
            type="text"
            onChange={(e) => setResource(e.target.value)} />
        </Form.Group>
        <fieldset
          id="uploadInput"
        >
          <Form.Group
            controlId="formFile"
            className="mb-3"
          >
            <Form.Label className={styles.formLabel}>
              {i18n.t('validator.labels.uploadResource')}
            </Form.Label>
            <Form.Control
              id="inputFile"
              type="file"
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                console.log(target.files);
                setFile(target.files ? target.files[0] : null);
              }}
            />
          </Form.Group>
        </fieldset>
        <Form.Group>
          <Form.Label className={styles.formLabel}>
            {i18n.t('validator.labels.selectProfile')}
          </Form.Label>
          <Form.Select
            value={selectedProfile}
            onChange={(e) => {
              setSelectedProfile(e.target.value);
            }}
          >
            <option>{i18n.t('validator.labels.defaultProfileOption')}</option>
            {profiles.map(profile => getProfileOptions(profile))}
          </Form.Select>
        </Form.Group>
        <Button
          className="button"
          variant="danger"
          onClick={sendToValidation}
        >
          {i18n.t('button.validate')}
        </Button>
        <Button
          className="button"
          variant="secondary"
          onClick={onReset}
        >
          {i18n.t('button.reset')}
        </Button>
      </div>
    </PandoraPage>
  );
};

export default ValidatorHome;

