// Font awesome
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import { FhirStatus, StatusAlert, StatusIcon, StatusTag, Title } from "@fyrstain/fhir-front-library";
import PandoraPage from '../../components/PandoraPage/PandoraPage';
// Dayjs
import dayjs from 'dayjs';
// FHIR
import Client from 'fhir-kit-client';
import { Bundle, TestReport, TestReportSetupAction, TestReportTestAction, TestScript } from "fhir/r5";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useEffect, useReducer, useState } from "react";
// React Bootstrap
import { Card, Form } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
// React Router
import { useNavigate, useParams } from "react-router-dom";
// Style
import styles from "./testReportDetails.module.css";

const TestReportDetails: FunctionComponent = () => {

    /////////////////////////////////////////////
    //                 State                   //
    /////////////////////////////////////////////

    const { testReportId } = useParams();
    const [tests, setTests] = useState([] as { toggle: boolean, operation: TestReportTestAction, asserts: TestReportTestAction[] }[]);
    const [setups, setSetups] = useState([] as { toggle: boolean, operation: TestReportSetupAction, asserts: TestReportSetupAction[] }[]);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [report, setReport] = useState({} as TestReport);
    const [script, setScript] = useState({} as TestScript);
    const [loading, setLoading] = useState(false);

    const [serverLabel, setServerLabel] = useState("");
    const [clientLabel, setClientLabel] = useState("");
    const [proxychannel, setProxyChannel] = useState("");

    /////////////////////////////////////////////
    //                  Client                 //
    /////////////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////////////////////
    //                 Functions               //
    /////////////////////////////////////////////

    const navigate = useNavigate();

    /**
     * Function to get the detail of the operation.
     * 
     * @param detail    the detail to get.
     **/
    function onDetail(detail: string | undefined) {
        let url = '';
        if (detail?.startsWith("Bundle/")) {
            url = "/ValidationReport/" + detail.split('/')[1];
        }
        if (detail?.startsWith("Parameters/")) {
            url = "/ExchangeDetails/" + detail.split('/')[1];
        }
        return url;
    }

    useEffect(() => {
        loadReport();
    }, []);

    /**
     * Load Report from the back to populate the table.
     * 
     * @returns the promise of a Test report.
     */
    async function loadReport() {
        setLoading(true);
        try {
            const response = await fhirClient.read({
                resourceType: 'TestReport',
                id: testReportId ?? '',
            })
            const testReport: TestReport = response as TestReport;
            setReport(testReport);
            setSetups(retrieveSetups(testReport));
            setTests(retrieveTests(testReport));

            const response2 = await fhirClient.search({
                resourceType: 'TestScript',
                searchParams: {
                    "url": testReport.testScript,
                }
            })
            const bundle: Bundle = response2 as Bundle;
            const script: TestScript = bundle.entry?.at(0)?.resource as TestScript;
            if (script) {
                setScript(script);
            }
            setClientLabel(testReport.participant?.find(participant => participant.type === "client")?.display ?? "N/A");
            setServerLabel(testReport.participant?.find(participant => participant.type === "server")?.display ?? "N/A");
            setProxyChannel(testReport.extension?.find(extension => extension.url === "ProxyChannelName")?.valueString ?? "N/A");
            setLoading(false);
        } catch (error) {
            console.log(error);
            onError();
        }
    }

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /**
     * Retrieve setups operations and asserts to populate the table.
     * 
     * @param report    the report to the Test report.
     */
    function retrieveSetups(report: TestReport) {
        const result: { toggle: boolean, operation: TestReportSetupAction, asserts: TestReportSetupAction[] }[] = [];
        report.setup?.action.filter(a => a.operation).forEach(a => {
            const isPass = a.operation?.result === 'pass';
            result.push({ "toggle": !isPass, "operation": a, asserts: report.setup?.action.filter(action => action.assert?.id?.includes(a.operation?.id ?? '')) ?? [] })
        })
        return result;
    }

    /**
     * Retrieve operations and asserts tests to populate the table.
     * 
     * @param report    the report to the Test report.
     */
    function retrieveTests(report: TestReport) {
        const result: { toggle: boolean, operation: TestReportTestAction, asserts: TestReportTestAction[] }[] = [];
        report.test?.forEach(test => {
            test.action.filter(a => a.operation).forEach(a => {
                const isPass = a.operation?.result === 'pass';
                result.push({ "toggle": !isPass, "operation": a, asserts: test.action.filter(action => action.assert?.id?.includes(a.id ?? '')) ?? [] })
            })
        })
        return result;
    }

    /**
     * Determine the overall status for a set of operations.
     * 
     * @param operations The operations to check.
     * @returns The overall status.
     */
    function prioritizeStatus(operations: (TestReportSetupAction | TestReportTestAction)[]) {
        let status = 'pass';
        let hasSkip = false;
        for (const operation of operations) {
            switch (operation.operation?.result) {
                case 'fail':
                    return 'fail';
                case 'error':
                    if (status !== 'fail') {
                        status = 'error';
                    }
                    break;
                case 'warning':
                    if (status !== 'fail' && status !== 'error') {
                        status = 'warning';
                    }
                    break;
                case 'skip':
                    hasSkip = true;
                    break;
                default:
                    break;
            }
        }
        if (status === 'pass' && hasSkip) {
            return 'pass';
        }
        return status;
    }

    /////////////////////////////////////////////
    //                Content                  //
    /////////////////////////////////////////////

    return (
        <PandoraPage titleKey='title.testreport' loading={loading} needsLogin={true} >
            <>
                <Card>
                    <Card.Header>
                        <Title level={2} content={'Informations'} />
                    </Card.Header>
                    <Card.Body className="cardBody">
                        <div className={styles.alert}>
                            <StatusAlert
                                status={FhirStatus[report.result as keyof typeof FhirStatus]}
                            >
                                <div className={styles.alertContainer}>
                                    <div>
                                        <StatusIcon
                                            status={FhirStatus[report.result as keyof typeof FhirStatus]}
                                        />
                                        <strong className={styles.alertTitle}>
                                            {report.id} / {report.name}
                                        </strong>
                                    </div>
                                </div>
                            </StatusAlert>
                        </div>
                        <div className={styles.form}>
                            <div>
                                <Form.Label className={styles.formTextLabel}>
                                    <strong>
                                        {i18n.t('testreport.card.description.title')}
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {script.description}
                                </Form.Text>
                            </div>
                            <div>
                                <Form.Label className={styles.formTextLabel}>
                                    <strong>
                                        {i18n.t('testreport.table.started')} :&nbsp;
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {dayjs(report.issued).fromNow()}
                                </Form.Text>
                            </div>
                            <div>
                                <Form.Label className={styles.formTextLabel}>
                                    <strong>
                                        {i18n.t('testreport.table.duration')}
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {Math.round(dayjs(report.meta?.lastUpdated).diff(report.issued, "second", true)) + i18n.t('testreport.table.seconds')}
                                </Form.Text>
                            </div>
                            <div>
                                <Form.Label className={styles.formTextLabel}>
                                    <strong>
                                        {i18n.t('label.client')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {clientLabel}
                                </Form.Text>
                            </div>
                            <div>
                                <Form.Label className={styles.formTextLabel}>
                                    <strong>
                                        {i18n.t('label.server')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {serverLabel}
                                </Form.Text>
                            </div>
                            <div>
                                <Form.Label className={styles.formTextLabel}>
                                    <strong>
                                        {i18n.t('label.proxychannel')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {proxychannel}
                                </Form.Text>
                            </div>
                        </div>
                    </Card.Body>
                </Card>

                <div className="section">
                    {setups.length > 0 ? (
                        <Accordion defaultActiveKey={setups.every(setup => setup.operation.operation?.result === 'pass') ? undefined : "0"}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <StatusTag
                                        status={FhirStatus[prioritizeStatus(setups.map(setup => setup.operation)) as keyof typeof FhirStatus]}
                                        statusMessage={prioritizeStatus(setups.map(setup => setup.operation))}
                                    />
                                    <div className={styles.titleContainer}>
                                        <Title level={2} content={'Setup'} />
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="container col-md-12 panel panel-default panel-body">
                                        <table className="table table-condensed table-striped">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        {i18n.t('table.row.name')}
                                                    </th>
                                                    <th>
                                                        {i18n.t('table.row.result')}
                                                    </th>
                                                    <th>
                                                        Messages
                                                    </th>
                                                    <th>
                                                        {i18n.t('table.row.details')}
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {setups.map(setup => <>
                                                    <tr className={styles.operationRow}>
                                                        <td className={styles.columnsWidthOperation}>
                                                            {setup.operation.operation?.id ?? 'N/A'}
                                                        </td>
                                                        <td className={styles.smallColumnsWidthOperation}>
                                                            <StatusTag
                                                                status={FhirStatus[setup.operation.operation?.result as keyof typeof FhirStatus]}
                                                                statusMessage={setup.operation.operation?.result ?? 'N/A'}
                                                            />
                                                        </td>
                                                        <td className={styles.largeColumnsWidthOperation}>
                                                            {setup.operation.operation?.message ?? 'N/A'}
                                                        </td>
                                                        <td className={styles.smallColumnsWidthOperation}>
                                                            <a
                                                                href={onDetail(setup.operation.operation?.detail)}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="actionIcon"
                                                                    icon={faEye}
                                                                />
                                                            </a>
                                                        </td>
                                                        <td>
                                                            {!setup.toggle && (
                                                                <FontAwesomeIcon
                                                                    className={styles.chevron}
                                                                    icon={faChevronUp}
                                                                    rotation={180}
                                                                    onClick={() => { setup.toggle = !setup.toggle; forceUpdate(); }} />
                                                            )}
                                                            {setup.toggle && (
                                                                <FontAwesomeIcon
                                                                    className={styles.chevron}
                                                                    icon={faChevronUp}
                                                                    onClick={() => { setup.toggle = !setup.toggle; forceUpdate(); }} />
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={12} className={styles.largeTable}>
                                                            {setup.toggle && (
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                    </thead>
                                                                    <tbody>
                                                                        {setup.asserts.map(assert => <tr>
                                                                            <td className={styles.columnsWidthAssert}>
                                                                                {assert.assert?.id ?? 'N/A'}
                                                                            </td>
                                                                            <td className={styles.smallColumnsWidthAssert}>
                                                                                <StatusTag
                                                                                    status={FhirStatus[assert.assert?.result as keyof typeof FhirStatus]}
                                                                                    statusMessage={assert.assert?.result ?? 'N/A'}
                                                                                />

                                                                            </td>
                                                                            <td className={styles.largeColumnsWidthAssert}>
                                                                                {assert.assert?.message ?? 'N/A'}
                                                                            </td>
                                                                            <td className={styles.smallColumnsWidthAssert}>
                                                                                <a
                                                                                    href={onDetail(assert.assert?.detail)}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        className="actionIcon"
                                                                                        icon={faEye}
                                                                                    />
                                                                                </a>
                                                                            </td>
                                                                            <td className={styles.hiddenAssertWidth}>
                                                                            </td>
                                                                        </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    ) : (
                        <Card>
                            <Card.Header className={styles.noOperationCard}>
                                <Title level={2} content={'Setup'} />
                                <b className={styles.infoMessage}>({i18n.t('infomessage.nooperation')})</b>
                            </Card.Header>
                        </Card>
                    )}
                </div>

                <div className="section">
                    {tests.length > 0 ? (
                        <Accordion defaultActiveKey={tests.every(test => test.operation.operation?.result === 'pass') ? undefined : "0"}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <StatusTag
                                        status={FhirStatus[prioritizeStatus(tests.map(test => test.operation)) as keyof typeof FhirStatus]}
                                        statusMessage={prioritizeStatus(tests.map(test => test.operation))}
                                    />
                                    <div className={styles.titleContainer}>
                                        <Title level={2} content={'Test'} />
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="container col-md-12 panel panel-default panel-body">
                                        <table className="table table-condensed table-striped">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        {i18n.t('table.row.name')}
                                                    </th>
                                                    <th>
                                                        {i18n.t('table.row.result')}
                                                    </th>
                                                    <th>
                                                        Messages
                                                    </th>
                                                    <th>
                                                        {i18n.t('table.row.details')}
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tests.map(test => <>
                                                    <tr className={styles.operationRow}>
                                                        <td className={styles.columnsWidthOperation}>
                                                            {test.operation.operation?.id ?? 'N/A'}
                                                        </td>
                                                        <td className={styles.smallColumnsWidthOperation}>
                                                            <StatusTag
                                                                status={FhirStatus[test.operation.operation?.result as keyof typeof FhirStatus]}
                                                                statusMessage={test.operation.operation?.result ?? 'N/A'}
                                                            />
                                                        </td>
                                                        <td className={styles.largeColumnsWidthOperation}>
                                                            {test.operation.operation?.message ?? 'N/A'}
                                                        </td>
                                                        <td className={styles.smallColumnsWidthOperation}>
                                                            <a
                                                                href={onDetail(test.operation.operation?.detail)}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="actionIcon"
                                                                    icon={faEye}
                                                                />
                                                            </a>
                                                        </td>
                                                        <td>
                                                            {!test.toggle && (
                                                                <FontAwesomeIcon
                                                                    className={styles.chevron}
                                                                    icon={faChevronUp}
                                                                    rotation={180}
                                                                    onClick={() => { test.toggle = !test.toggle; forceUpdate(); }} />
                                                            )}
                                                            {test.toggle && (
                                                                <FontAwesomeIcon
                                                                    className={styles.chevron}
                                                                    icon={faChevronUp}
                                                                    onClick={() => { test.toggle = !test.toggle; forceUpdate(); }} />
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={12} className={styles.largeTable}>
                                                            {test.toggle && (
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                    </thead>
                                                                    <tbody>
                                                                        {test.asserts.map(assert => <tr>
                                                                            <td className={styles.columnsWidthAssert}>
                                                                                {assert.assert?.id ?? 'N/A'}
                                                                            </td>
                                                                            <td className={styles.smallColumnsWidthAssert}>
                                                                                <StatusTag
                                                                                    status={FhirStatus[assert.assert?.result as keyof typeof FhirStatus]}
                                                                                    statusMessage={assert.assert?.result ?? 'N/A'}
                                                                                />
                                                                            </td>
                                                                            <td className={styles.largeColumnsWidthAssert}>
                                                                                {assert.assert?.message ?? 'N/A'}
                                                                            </td>
                                                                            <td className={styles.smallColumnsWidthAssert}>
                                                                                <a
                                                                                    href={onDetail(assert.assert?.detail)}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        className="actionIcon"
                                                                                        icon={faEye}
                                                                                    />
                                                                                </a>
                                                                            </td>
                                                                            <td className={styles.hiddenAssertWidth}>
                                                                            </td>
                                                                        </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    ) : (
                        <Card>
                            <Card.Header className={styles.noOperationCard}>
                                <Title level={2} content={'Test'} />
                                <b className={styles.infoMessage}>({i18n.t('infomessage.nooperation')})</b>
                            </Card.Header>
                        </Card>
                    )}
                </div>

                <div className="section">
                    {report.teardown?.action?.some(action => action.operation) ? (
                        <Accordion defaultActiveKey={report.teardown?.action?.some(action => action.operation?.result !== 'pass') ? "0" : undefined}>                            <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <StatusTag
                                    status={FhirStatus[prioritizeStatus(report.teardown?.action ?? []) as keyof typeof FhirStatus]}
                                    statusMessage={prioritizeStatus(report.teardown?.action ?? [])}
                                />
                                <div className={styles.titleContainer}>
                                    <Title level={2} content={'Teardown'} />
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="container col-md-12 panel panel-default panel-body">
                                    <table className="table table-condensed table-striped">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {i18n.t('table.row.name')}
                                                </th>
                                                <th>
                                                    {i18n.t('table.row.result')}
                                                </th>
                                                <th>
                                                    Messages
                                                </th>
                                                <th>
                                                    {i18n.t('table.row.details')}
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {report.teardown?.action.map(a => <tr className={styles.operationRow}>
                                                <td className={styles.columnsWidthOperation}>
                                                    {a.operation.id}
                                                </td>
                                                <td className={styles.smallColumnsWidthOperation}>
                                                    <StatusTag
                                                        status={FhirStatus[a.operation.result as keyof typeof FhirStatus]}
                                                        statusMessage={a.operation.result ?? 'N/A'}
                                                    />
                                                </td>
                                                <td className={styles.largeColumnsWidthOperation}>
                                                    {a.operation.message ?? 'N/A'}
                                                </td>
                                                <td className={styles.smallColumnsWidthOperation}>
                                                    <a
                                                        href={onDetail(a.operation.detail)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <FontAwesomeIcon
                                                            className="actionIcon"
                                                            icon={faEye}
                                                        />
                                                    </a>
                                                </td>
                                                <td className={styles.hiddenAssertWidth}>
                                                </td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                    ) : (
                        <Card>
                            <Card.Header className={styles.noOperationCard}>
                                <Title level={2} content={'Teardown'} />
                                <b className={styles.infoMessage}>({i18n.t('infomessage.nooperation')})</b>
                            </Card.Header>
                        </Card>
                    )}
                </div>
            </>
        </PandoraPage >
    );
};

export default TestReportDetails;
