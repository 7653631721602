// Font Awesome 
import { faPen, faTrashCan, faUpload } from "@fortawesome/free-solid-svg-icons";
// Fhir Front Library
import { SearchableTable } from "@fyrstain/fhir-front-library";
// FHIR
import Client from "fhir-kit-client";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useEffect, useState } from "react";
// React Bootstrap
import { Button, Form } from "react-bootstrap";
// Navigation
import { useNavigate } from "react-router-dom";
// Components
import PandoraPage from "../../components/PandoraPage/PandoraPage";
import { SimpleCode, ValueSetLoader } from "@fyrstain/fhir-front-library";

const Systems: FunctionComponent = () => {

    /////////////////////////////////////
    //            Constants            //
    /////////////////////////////////////

    const systemTypesUrl = process.env.REACT_APP_VALUESET_SYSTEMTYPES_URL ?? 'http://fyrstain.com/pdt/ValueSet/systemTypes';
    const standardsUrl = process.env.REACT_APP_VALUESET_STANDARDCODES_URL ?? 'http://fyrstain.com/pdt/ValueSet/standardCodes';
    const pandoraStandardsCodeSystemUrl = process.env.REACT_APP_CODESYSTEM_PANDORASTANDARDS ?? 'http://fyrstain.com/pdt/CodeSystem/PandoraStandards';

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////

    const [deviceType, setDeviceType] = useState(undefined as SimpleCode | undefined);
    const [standard, setStandard] = useState(undefined as SimpleCode | undefined);
    const [devicetypes, setDeviceTypes] = useState([] as SimpleCode[]);
    const [standards, setStandards] = useState([] as SimpleCode[]);
    const [refreshKey, setRefreshKey] = useState(0);

    /////////////////////////////////////
    //             Client              //
    /////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    const valueSetLoader = new ValueSetLoader(fhirClient);

    /////////////////////////////////////
    //           Navigation            //
    /////////////////////////////////////

    const navigate = useNavigate();

    const onEditClick = useCallback((id: string) => {
        navigate("/SystemEdition/" + id);
    }, [navigate]);

    const onValidatorUpload = useCallback((id: string) => {
        navigate("/PackageLoader/" + id);
    }, [navigate]);

    const onRegisterSystem = useCallback(() => {
        navigate("/SystemRegistration");
    }, [navigate]);

    /////////////////////////////////////
    //             Errors              //
    /////////////////////////////////////

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /////////////////////////////////////
    //             Actions             //
    /////////////////////////////////////

    /**
     * Delete a System from the server.
     *
     * @param id    the id of the System.
     */
    async function deleteSystem(id: string) {
        try {
            await fhirClient.delete({
                resourceType: 'Device',
                id: id
            });
            setRefreshKey(oldKey => oldKey + 1);
        } catch (error) {
            onError();
        }
    }

    /**
     * Get a boolean version of the active/inactive status.
     * 
     * @param status the string status.
     * @returns true if active.
     */
    function getActive(status: string): boolean {
        return status === "active";
    }

    /**
     * Perform a PATCH on the server to update the status
     * 
     * @param id        the id of the resource.
     * @param active    true if active.
     */
    async function switchStatus(id: string, active: boolean) {
        try {
            await fhirClient.patch({
                id: id,
                resourceType: 'Device',
                JSONPatch: [
                    {
                        path: "/status",
                        op: 'replace',
                        value: active ? "active" : "inactive"
                    }
                ]
            });
        } catch (error) {
            console.log(error);
            onError();
        }
    }

    function isValidator(item: any): boolean {
        return "Validator" === item.deviceType;
    }

    /////////////////////////////////////
    //          Page Loading           //
    /////////////////////////////////////

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadPage();
    }, []);

    /**
     * Load the initial state of the page.
     */
    async function loadPage() {
        setLoading(true);
        try {
            setDeviceTypes(await valueSetLoader.searchValuSet(systemTypesUrl));
            setStandards(await valueSetLoader.searchValuSet(standardsUrl));
        } catch (error) {
            console.log(error);
            onError();
        }
        setLoading(false);
    }

    /////////////////////////////////////
    //             Content             //
    /////////////////////////////////////

    /**
     * Get the option element to represent the code in an Input Select.
     * @param code the code.
     * @returns the option element.
     */
    function getOption(code: SimpleCode) {
        return { value: code.code, label: code.display ?? code.code };
    }

    return (
        <PandoraPage titleKey='title.systems' loading={loading} needsLogin={true} >
            <>
                <SearchableTable
                    key={refreshKey}
                    searchCriteriaProps={{
                        title: i18n.t('title.searchcriteria'),
                        primaryButtonLabel: i18n.t('button.search'),
                        secondaryButtonLabel: i18n.t('button.reset'),
                        language: i18n.t,
                        searchResultModifier:
                        {
                            _sort: "-_lastUpdated",
                        },
                        criteria: [
                            {
                                inputProps: {
                                    label: i18n.t('label.name'),
                                    type: "text",
                                },
                                searchParamsName: "device-name:contains",
                            },
                            {
                                selectProps: {
                                    value: "null",
                                    defaultValue: i18n.t('defaultselectoption.status'),
                                    options: [
                                        { value: 'active', label: i18n.t('defaultselectoption.active') },
                                        { value: 'inactive', label: i18n.t('defaultselectoption.inactive') },
                                    ],
                                },
                                searchParamsName: "status",
                            },
                            {
                                selectProps: {
                                    value: deviceType ? deviceType.code : "null",
                                    defaultValue: i18n.t('defaultselectoption.devicetype'),
                                    options: devicetypes.map(getOption),
                                },
                                searchParamsName: "type",
                            },
                            {
                                selectProps: {
                                    value: standard ? standard.code : "null",
                                    defaultValue: i18n.t('defaultselectoption.standard'),
                                    options: standards.map(getOption),
                                },
                                searchParamsName: "standard",
                            },
                        ],
                    }}
                    paginationTableProps={{
                        columns: [
                            {
                                header: i18n.t('label.name'),
                                dataField: "name",
                                width: "15%",
                                tabletWidth: "11%",
                            },
                            {
                                header: i18n.t('label.owner'),
                                dataField: "owner",
                                width: "20%",
                                tabletWidth: "12%",
                            },
                            {
                                header: i18n.t('label.devicetype'),
                                dataField: "deviceType",
                                width: "15%",
                                tabletWidth: "17%",
                            },
                            {
                                header: i18n.t('label.deviceversion'),
                                dataField: "deviceVersion",
                                width: "15%",
                                tabletWidth: "21%",
                            },
                            {
                                header: i18n.t('label.standard'),
                                dataField: "standard",
                                width: "15%",
                                tabletWidth: "17%",
                            },
                            {
                                header: i18n.t('label.active'),
                                dataField: "status",
                                width: "10%",
                                formatter: (cell: string, row: { id: string }) => {
                                    return <Form >
                                        <Form.Check
                                            type="switch"
                                            defaultChecked={getActive(cell)}
                                            onChange={e => switchStatus(row.id, e.target.checked)}
                                        // TODO when you switch status in the table and launch the search 
                                        // it keeps the initial state passed to the system and does not update
                                        // the table display.
                                        // for example, if a system is recorded as 'active' and we change it to
                                        // 'inactive' in the table, when we launch the search on the active statuses
                                        // the table keeps the display of this system whereas it should disappear results.
                                        />
                                    </Form >
                                },
                            },
                        ],
                        action: [
                            {
                                icon: faPen,
                                onClick: onEditClick,
                            },
                            {
                                icon: faTrashCan,
                                onClick: deleteSystem,
                            },
                            {
                                icon: faUpload,
                                onClick: onValidatorUpload,
                                display: isValidator,
                            },
                        ],
                        mapResourceToData: (resource: any) => {
                            return {
                                name: resource.displayName,
                                owner: "N/A",
                                deviceType: resource.type?.at(0)?.coding?.at(0)?.display ?? "N/A",
                                deviceVersion: resource.version?.find((version: any) => version.type?.coding?.at(0)?.code === "531975")?.value ?? "N/A",
                                standard: resource.version?.filter(
                                    (version: any) => version.type?.coding?.at(0)?.system === pandoraStandardsCodeSystemUrl
                                ).map(
                                    (version: any) => version.type?.coding?.at(0)?.display + " (" + version.value + ")"
                                ).join(' '),
                                status: resource.status,
                                id: resource.id,
                            };
                        },
                        searchProps: {
                            serverUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir',
                            resourceType: 'Device',
                        },
                        onError: onError,
                    }}
                />
                <Button
                    className="button fit"
                    variant="danger"
                    onClick={onRegisterSystem}
                >
                    {i18n.t('button.registersystem')}
                </Button>
            </>
        </PandoraPage>
    );
};

export default Systems;