// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testPlanViewer_label__uBAqW {
    margin: 0 1rem 1rem 0;
    line-height: 1.2rem;
}

.testPlanViewer_formLabel__JZLC5 {
    text-align: justify;
}

.testPlanViewer_formText__Ytf-Q {
    margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/testPlanViewer/testPlanViewer.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".label {\r\n    margin: 0 1rem 1rem 0;\r\n    line-height: 1.2rem;\r\n}\r\n\r\n.formLabel {\r\n    text-align: justify;\r\n}\r\n\r\n.formText {\r\n    margin-bottom: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `testPlanViewer_label__uBAqW`,
	"formLabel": `testPlanViewer_formLabel__JZLC5`,
	"formText": `testPlanViewer_formText__Ytf-Q`
};
export default ___CSS_LOADER_EXPORT___;
