// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testReportDetails_formTextLabel__SUXzD {
    margin: 1rem 1rem 0 1.5rem;
    text-align: justify;
    line-height: 1.2rem;
}

.testReportDetails_alert__xPA0R {
    width: 97%;
    margin: auto;
}

.testReportDetails_form__\\+IIb6 {
    margin: 2rem 0 0 0;
}

.testReportDetails_alertTitle__hfcD- {
    margin-left: 1rem;
}

.testReportDetails_successText__kp1v9 {
    margin-top: 1rem;
}

.testReportDetails_titleContainer__9mPXN {
    margin-left: 1rem;
}

.testReportDetails_noOperationCard__\\+V1kA {
    background-color: white !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.testReportDetails_infoMessage__fNknT {
    margin-left: 1rem;
}

.testReportDetails_largeColumnsWidthOperation__Rn\\+fo {
    width: 43%;
}

.testReportDetails_columnsWidthOperation__Vqgu8 {
    width: 22%;
}

.testReportDetails_smallColumnsWidthOperation__KgrlO {
    width: 15%;
}

.testReportDetails_largeColumnsWidthAssert__9HjDr {
    width: 43.6%;
}

.testReportDetails_columnsWidthAssert__VKvMO {
    width: 21.5%;
}

.testReportDetails_smallColumnsWidthAssert__fHoR7 {
    width: 15.3%;
}

.testReportDetails_chevron__NqAa9 {
    cursor: pointer;
}

.testReportDetails_hiddenAssertWidth__F9pjv {
    width: 5rem;
}

.testReportDetails_largeTable__NUxTJ {
    width: 100%;
}

.testReportDetails_operationRow__rS5E4 {
    border: var(--color-lightGray) solid 0.1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/testReportDetails/testReportDetails.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;IAClC,aAAa;IACb,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,2CAA2C;AAC/C","sourcesContent":[".formTextLabel {\r\n    margin: 1rem 1rem 0 1.5rem;\r\n    text-align: justify;\r\n    line-height: 1.2rem;\r\n}\r\n\r\n.alert {\r\n    width: 97%;\r\n    margin: auto;\r\n}\r\n\r\n.form {\r\n    margin: 2rem 0 0 0;\r\n}\r\n\r\n.alertTitle {\r\n    margin-left: 1rem;\r\n}\r\n\r\n.successText {\r\n    margin-top: 1rem;\r\n}\r\n\r\n.titleContainer {\r\n    margin-left: 1rem;\r\n}\r\n\r\n.noOperationCard {\r\n    background-color: white !important;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    align-items: center;\r\n}\r\n\r\n.infoMessage {\r\n    margin-left: 1rem;\r\n}\r\n\r\n.largeColumnsWidthOperation {\r\n    width: 43%;\r\n}\r\n\r\n.columnsWidthOperation {\r\n    width: 22%;\r\n}\r\n\r\n.smallColumnsWidthOperation {\r\n    width: 15%;\r\n}\r\n\r\n.largeColumnsWidthAssert {\r\n    width: 43.6%;\r\n}\r\n\r\n.columnsWidthAssert {\r\n    width: 21.5%;\r\n}\r\n\r\n.smallColumnsWidthAssert {\r\n    width: 15.3%;\r\n}\r\n\r\n.chevron {\r\n    cursor: pointer;\r\n}\r\n\r\n.hiddenAssertWidth {\r\n    width: 5rem;\r\n}\r\n\r\n.largeTable {\r\n    width: 100%;\r\n}\r\n\r\n.operationRow {\r\n    border: var(--color-lightGray) solid 0.1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formTextLabel": `testReportDetails_formTextLabel__SUXzD`,
	"alert": `testReportDetails_alert__xPA0R`,
	"form": `testReportDetails_form__+IIb6`,
	"alertTitle": `testReportDetails_alertTitle__hfcD-`,
	"successText": `testReportDetails_successText__kp1v9`,
	"titleContainer": `testReportDetails_titleContainer__9mPXN`,
	"noOperationCard": `testReportDetails_noOperationCard__+V1kA`,
	"infoMessage": `testReportDetails_infoMessage__fNknT`,
	"largeColumnsWidthOperation": `testReportDetails_largeColumnsWidthOperation__Rn+fo`,
	"columnsWidthOperation": `testReportDetails_columnsWidthOperation__Vqgu8`,
	"smallColumnsWidthOperation": `testReportDetails_smallColumnsWidthOperation__KgrlO`,
	"largeColumnsWidthAssert": `testReportDetails_largeColumnsWidthAssert__9HjDr`,
	"columnsWidthAssert": `testReportDetails_columnsWidthAssert__VKvMO`,
	"smallColumnsWidthAssert": `testReportDetails_smallColumnsWidthAssert__fHoR7`,
	"chevron": `testReportDetails_chevron__NqAa9`,
	"hiddenAssertWidth": `testReportDetails_hiddenAssertWidth__F9pjv`,
	"largeTable": `testReportDetails_largeTable__NUxTJ`,
	"operationRow": `testReportDetails_operationRow__rS5E4`
};
export default ___CSS_LOADER_EXPORT___;
