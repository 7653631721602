// FHIR
import Client from "fhir-kit-client";
import { DocumentReference, TestScript, TestScriptFixture, TestScriptTestAction } from "fhir/r5";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useEffect, useReducer, useState } from "react";
// React Bootstrap
import { Accordion, Button, Card, Form } from "react-bootstrap";
// React Router
import { useNavigate, useParams } from "react-router-dom";
// Components
import PandoraPage from "../../components/PandoraPage/PandoraPage";
import { Title, StatusTag, FhirStatus } from "@fyrstain/fhir-front-library";
// CSS
import styles from "./testScriptViewer.module.css";

const TestScriptViewer: FunctionComponent = () => {

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////

    const [loading, setLoading] = useState(true);
    const [tests, setTests] = useState([] as { toggle: boolean, operation: TestScriptTestAction, asserts: TestScriptTestAction[] }[]);
    const [setups, setSetups] = useState([] as { toggle: boolean, operation: TestScriptTestAction, asserts: TestScriptTestAction[] }[]);
    const { testScriptId } = useParams();
    const [testScriptName, setTestScriptName] = useState("");
    const [testScriptDescription, setTestScriptDescription] = useState("");
    const [testScriptStatus, setTestScriptStatus] = useState("");
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [toggleSetupOperation, setToggleSetupOperation] = useState(true);
    const [toggleTestOperation, setToggleTestOperation] = useState(true);
    const [script, setScript] = useState({} as TestScript);
    const [showOperationButton, setShowOperationButton] = useState(false);
    const [fixtures, setFixtures] = useState([] as { toggle: boolean, fixture: TestScriptFixture }[]);
    const [closedFixture, setClosedFixture] = useState(false);
    const [fixtureId, setFixtureId] = useState("");

    /////////////////////////////////////
    //             Client              //
    /////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////////////
    //             Action              //
    /////////////////////////////////////

    const navigate = useNavigate();

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    useEffect(() => {
        loadTestScriptInformations();
    }, []);

    const lauchTest = useCallback((id: string) => {
        navigate("/SystemsSelection/" + id);
    }, [navigate]);

    /**
  * Navigate to the Fixture Edition page from the Document Reference type fixture id
  * 
  * @param fixtureId    the id of the fixture.
  * @returns the promise of a DocumentReference.
  * 
  */
    async function navigateToFixtureEdition(fixtureId: string) {
        try {
            const fixture = script.fixture?.find(f => f.id === fixtureId);
            if (fixture && fixture.resource && fixture.resource.reference) {
                const resourceId = fixture.resource.reference.split("/").pop();
                navigate("/FixtureEdition/" + resourceId);
            }
        } catch (error) {
            console.log(error);
            onError();
        }
    }

    /**
    * Load Test script informations from the back to populate the page.
    * 
    * @returns the promise of a TestScript.
    */
    async function loadTestScriptInformations() {
        setLoading(true);
        try {
            const response = await fhirClient.read({
                resourceType: "TestScript",
                id: testScriptId ?? '',
            });
            const testScript = response as TestScript;
            setTestScriptName(testScript.name ?? "");
            setTestScriptDescription(testScript.description ?? "");
            setTestScriptStatus(testScript.status ?? "");
            setSetups(retrieveSetups(testScript));
            setTests(retrieveTests(testScript));
            setScript(testScript);
            setFixtures(retrieveFixture(testScript));
            setLoading(false);
        } catch (error) {
            console.log(error);
            onError();
        }
    }

    /**
 * Retrieve setups operations and asserts to populate the fields.
 * 
 * @param script    the setup to the Test script.
 */
    function retrieveSetups(script: TestScript) {
        const result: { toggle: boolean, operation: TestScriptTestAction, asserts: TestScriptTestAction[] }[] = [];
        script.setup?.action.filter(a => a.operation).forEach(a => {
            result.push({ "toggle": false, "operation": a, asserts: script.setup?.action.filter(action => action.assert?.id?.includes(a.operation?.id ?? '')) ?? [] })
        })
        return result;
    }

    /**
     * Retrieve tests operations and asserts tests to populate the fields.
     * 
     * @param script    the test to the Test script.
     */
    function retrieveTests(script: TestScript) {
        const result: { toggle: boolean, operation: TestScriptTestAction, asserts: TestScriptTestAction[] }[] = [];
        script.test?.forEach(test => {
            test.action.filter(a => a.operation).forEach(a => {
                result.push({ "toggle": false, "operation": a, asserts: test.action.filter(action => action.assert?.id?.includes(a.id ?? '')) ?? [] })
            })
        })
        return result;
    }

    /**
    * Retrieve fixtures to populate the fields.
    * 
    * @param script    the test to the Test script.
    */
    function retrieveFixture(script: TestScript) {
        const result: { toggle: boolean, fixture: TestScriptFixture }[] = [];
        script.fixture?.forEach(f => {
            result.push({ "toggle": false, "fixture": f })
        })
        return result;
    }

    /////////////////////////////////////
    //             Content              //
    /////////////////////////////////////

    return (
        <PandoraPage titleKey='title.testscriptviewer' loading={loading} needsLogin={true}>
            <>
                <div className="section">
                    <Card className={styles.card}>
                        <Card.Header>
                            <Title level={2} content='Informations' />
                        </Card.Header>
                        <Card.Body className="cardBody">
                            <div>
                                <div>
                                    <Form.Label className={styles.formLabel}>
                                        <strong>
                                            ID :
                                        </strong>
                                    </Form.Label>
                                    <Form.Text>
                                        {testScriptId}
                                    </Form.Text>
                                </div>
                                <div>
                                    <Form.Label className={styles.formLabel}>
                                        <strong>
                                            {i18n.t('label.name')} :
                                        </strong>
                                    </Form.Label>
                                    <Form.Text>
                                        {testScriptName}
                                    </Form.Text>
                                </div>
                                <div className="flexWrap">
                                    <Form.Label className={styles.formLabel}>
                                        <strong>
                                            {i18n.t('label.resourcestatus')} :
                                        </strong>
                                    </Form.Label>
                                    <div className={styles.statusTag}>

                                        <Form.Text className={styles.formText}>
                                            <StatusTag
                                                status={FhirStatus[testScriptStatus as keyof typeof FhirStatus]}
                                                statusMessage={testScriptStatus}
                                            />
                                        </Form.Text>
                                    </div>
                                </div>
                                <div>
                                    <Form.Label className={styles.formLabel}>
                                        <strong>
                                            {i18n.t('label.generaldescription')} :
                                        </strong>
                                    </Form.Label>
                                    <Form.Text>
                                        {testScriptDescription}
                                    </Form.Text>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className="section">
                    <Accordion defaultActiveKey="none">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <Title level={2} content={'Setup'} />
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="flexWrap">
                                    {setups.map(setup =>
                                        <div>
                                            <>
                                                {setup.toggle ?
                                                    <div className="flexColumnContainerAlignStart">
                                                        <div className={styles.title3Container}>
                                                            <Title level={3} content={`${setup.operation.operation?.id ?? 'N/A'}/${setup.operation.operation?.label ?? 'N/A'}`} />
                                                        </div>
                                                        <div className="flexWrap">
                                                            {setup.asserts.map(assert =>
                                                                <div>
                                                                    <Card className={styles.assertCard}>
                                                                        <div className={styles.assertCardContainer}>
                                                                            <Card.Body>
                                                                                <Card.Title>
                                                                                    {assert.assert?.id ?? 'N/A'}
                                                                                </Card.Title>
                                                                                <br />
                                                                                <Card.Text>
                                                                                    <div>
                                                                                        <Form.Label className={styles.formLabel}>
                                                                                            <strong>
                                                                                                {i18n.t('label.name')} :
                                                                                            </strong>
                                                                                        </Form.Label>
                                                                                        <Form.Text>
                                                                                            {assert.assert?.label ?? 'N/A'}
                                                                                        </Form.Text>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Form.Label className={styles.formLabel}>
                                                                                            <strong>
                                                                                                Direction:
                                                                                            </strong>
                                                                                        </Form.Label>
                                                                                        <Form.Text>
                                                                                            {assert.assert?.direction ?? 'N/A'}
                                                                                        </Form.Text>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Form.Text>
                                                                                            {assert.assert?.description ?? 'N/A'}
                                                                                        </Form.Text>
                                                                                    </div>
                                                                                </Card.Text>
                                                                            </Card.Body>
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {!showOperationButton &&
                                                            <div>
                                                                <Button className={styles.operationButton}
                                                                    variant="primary"
                                                                    onClick={() => {
                                                                        setup.toggle = !setup.toggle;
                                                                        forceUpdate();
                                                                        setToggleSetupOperation(true);
                                                                    }}
                                                                >
                                                                    {i18n.t('button.seeoperations')}
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    <>
                                                        {toggleSetupOperation &&
                                                            <Card className={styles.operationCard}>
                                                                <div className={styles.operationCardContainer}>
                                                                    <Card.Body>
                                                                        <Card.Title>
                                                                            {setup.operation.operation?.id ?? 'N/A'}
                                                                        </Card.Title>
                                                                        <br />
                                                                        <Card.Text>
                                                                            <div>
                                                                                <Form.Label className={styles.formLabel}>
                                                                                    {i18n.t('label.name')} :
                                                                                </Form.Label>
                                                                                <Form.Text>
                                                                                    <span className={styles.whiteText}>
                                                                                        {setup.operation.operation?.label ?? 'N/A'}
                                                                                    </span>
                                                                                </Form.Text>
                                                                            </div>
                                                                            <Form.Label className={styles.formLabel}>
                                                                                {i18n.t('label.method')} :
                                                                            </Form.Label>
                                                                            <Form.Text>
                                                                                <span className={styles.whiteText}>
                                                                                    {setup.operation.operation?.method?.toLocaleUpperCase() ?? 'N/A'}
                                                                                </span>
                                                                            </Form.Text>
                                                                            <br />
                                                                            <Form.Label className={styles.formLabel}>
                                                                                {i18n.t('label.fixture')} :
                                                                            </Form.Label>
                                                                            <Form.Text>
                                                                                <a className={styles.whiteText}
                                                                                    href="#fixture"
                                                                                    onClick={() => {
                                                                                        const updatedFixtures = fixtures.map(f =>
                                                                                        ({
                                                                                            ...f,
                                                                                            toggle: f.fixture.id === setup.operation.operation?.targetId || f.fixture.id === setup.operation.operation?.sourceId
                                                                                        })
                                                                                        );
                                                                                        setFixtures(updatedFixtures);
                                                                                        setClosedFixture(false);
                                                                                    }}                                                                          >
                                                                                    {(setup.operation.operation?.targetId || setup.operation.operation?.sourceId) ?? 'N/A'}
                                                                                </a>
                                                                            </Form.Text>
                                                                            <br />
                                                                            <br />
                                                                            {setup.operation.operation?.description ?? 'N/A'}
                                                                        </Card.Text>
                                                                        <div className="displayFlexCenter">
                                                                            <Button
                                                                                className={styles.assertButton}
                                                                                variant="primary"
                                                                                onClick={() => {
                                                                                    setup.toggle = !setup.toggle;
                                                                                    forceUpdate();
                                                                                    setToggleSetupOperation(false);
                                                                                    setClosedFixture(true);
                                                                                }}
                                                                            >
                                                                                {i18n.t('button.seeasserts')}
                                                                            </Button>
                                                                        </div>
                                                                    </Card.Body>
                                                                </div>
                                                            </Card>
                                                        }
                                                    </>
                                                }
                                            </>
                                        </div>
                                    )}
                                </div>
                            </Accordion.Body >
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <Title level={2} content={'Test'} />
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="flexWrap">
                                    {tests.map(test =>
                                        <div>
                                            <>
                                                {test.toggle ?
                                                    <div className="flexColumnContainerAlignStart">
                                                        <div className={styles.title3Container}>
                                                            <Title level={3} content={`${test.operation.operation?.id ?? 'N/A'}/${test.operation.operation?.label ?? 'N/A'}`} />
                                                        </div>
                                                        <div className="flexWrap">
                                                            {test.asserts.map(assert =>
                                                                <div>
                                                                    <Card className={styles.assertCard}>
                                                                        <div className={styles.assertCardContainer}>
                                                                            <Card.Body>
                                                                                <Card.Title>
                                                                                    {assert.assert?.id ?? 'N/A'}
                                                                                </Card.Title>
                                                                                <br />
                                                                                <Card.Text>
                                                                                    <div>
                                                                                        <Form.Label className={styles.formLabel}>
                                                                                            <strong>
                                                                                                {i18n.t('label.name')} :
                                                                                            </strong>
                                                                                        </Form.Label>
                                                                                        <Form.Text>
                                                                                            {assert.assert?.label ?? 'N/A'}
                                                                                        </Form.Text>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Form.Label className={styles.formLabel}>
                                                                                            <strong>
                                                                                                Direction:
                                                                                            </strong>
                                                                                        </Form.Label>
                                                                                        <Form.Text>
                                                                                            {assert.assert?.direction ?? 'N/A'}
                                                                                        </Form.Text>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Form.Text>
                                                                                            {assert.assert?.description ?? 'N/A'}
                                                                                        </Form.Text>
                                                                                    </div>
                                                                                </Card.Text>
                                                                            </Card.Body>
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {!showOperationButton &&
                                                            <div className="displayFlexCenter">
                                                                <Button
                                                                    className={styles.operationButton}
                                                                    variant="primary"
                                                                    onClick={() => { test.toggle = !test.toggle; forceUpdate(); setToggleTestOperation(true); }}
                                                                >
                                                                    {i18n.t('button.seeoperations')}
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    <>
                                                        {toggleTestOperation &&
                                                            <Card className={styles.operationCard}>
                                                                <div className={styles.operationCardContainer}>
                                                                    <Card.Body>
                                                                        <Card.Title>
                                                                            {test.operation.operation?.id ?? 'N/A'}
                                                                        </Card.Title>
                                                                        <br />
                                                                        <Card.Text>
                                                                            <div>
                                                                                <Form.Label className={styles.formLabel}>
                                                                                    {i18n.t('label.name')} :
                                                                                </Form.Label>
                                                                                <Form.Text>
                                                                                    <span className={styles.whiteText}>
                                                                                        {test.operation.operation?.label ?? 'N/A'}
                                                                                    </span>
                                                                                </Form.Text>
                                                                            </div>
                                                                            <Form.Label className={styles.formLabel}>
                                                                                {i18n.t('label.method')} :
                                                                            </Form.Label>
                                                                            <Form.Text>
                                                                                <span className={styles.whiteText}>
                                                                                    {test.operation.operation?.method?.toLocaleUpperCase() ?? 'N/A'}
                                                                                </span>
                                                                            </Form.Text>
                                                                            <br />
                                                                            <Form.Label className={styles.formLabel}>
                                                                                {i18n.t('label.fixture')} :
                                                                            </Form.Label>
                                                                            <Form.Text>
                                                                                <a className={styles.whiteText}
                                                                                    href="#fixture"
                                                                                    onClick={() => {
                                                                                        const updatedFixtures = fixtures.map(f =>
                                                                                        ({
                                                                                            ...f,
                                                                                            toggle: f.fixture.id === test.operation.operation?.targetId || f.fixture.id === test.operation.operation?.sourceId
                                                                                        })
                                                                                        );
                                                                                        setFixtures(updatedFixtures);
                                                                                        setClosedFixture(false);
                                                                                    }}
                                                                                >
                                                                                    {(test.operation.operation?.targetId || test.operation.operation?.sourceId) ?? 'N/A'}
                                                                                </a>
                                                                            </Form.Text>
                                                                            <br />
                                                                            {test.operation.operation?.description ?? 'N/A'}
                                                                        </Card.Text>
                                                                        <div className="displayFlexCenter">
                                                                            <Button
                                                                                className={styles.assertButton}
                                                                                variant="primary"
                                                                                onClick={() => {
                                                                                    test.toggle = !test.toggle;
                                                                                    forceUpdate();
                                                                                    setToggleTestOperation(false);
                                                                                    setClosedFixture(true)
                                                                                }}
                                                                            >
                                                                                {i18n.t('button.seeasserts')}
                                                                            </Button>
                                                                        </div>
                                                                    </Card.Body>
                                                                </div>
                                                            </Card>
                                                        }
                                                    </>
                                                }
                                            </>
                                        </div>
                                    )}
                                </div>
                            </Accordion.Body >
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <Title level={2} content={'Teardown'} />
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="flexWrap">
                                    {script.teardown?.action.map(a =>
                                        <Card className={styles.operationCard}>
                                            <div className={styles.operationCardContainer}>
                                                <Card.Body>
                                                    <Card.Title>
                                                        {a.operation?.id ?? 'N/A'}
                                                    </Card.Title>
                                                    <br />
                                                    <Card.Text>
                                                        <div>
                                                            <Form.Label className={styles.formLabel}>
                                                                {i18n.t('label.name')} :
                                                            </Form.Label>
                                                            <Form.Text>
                                                                <span className={styles.whiteText}>
                                                                    {a.operation?.label ?? 'N/A'}
                                                                </span>
                                                            </Form.Text>
                                                        </div>
                                                        <Form.Label className={styles.formLabel}>
                                                            {i18n.t('label.method')} :
                                                        </Form.Label>
                                                        <Form.Text>
                                                            <span className={styles.whiteText}>
                                                                {a.operation?.method?.toLocaleUpperCase() ?? 'N/A'}
                                                            </span>
                                                        </Form.Text>
                                                        <br />
                                                        <Form.Label className={styles.formLabel}>
                                                            {i18n.t('label.fixture')} :
                                                        </Form.Label>
                                                        <Form.Text>
                                                            <a className={styles.whiteText}
                                                                href="#fixture"
                                                                onClick={() => {
                                                                    const updatedFixtures = fixtures.map(f =>
                                                                    ({
                                                                        ...f,
                                                                        toggle: f.fixture.id === a.operation?.targetId || f.fixture.id === a.operation?.sourceId
                                                                    })
                                                                    );
                                                                    setFixtures(updatedFixtures);
                                                                    setClosedFixture(false);
                                                                }}
                                                            >
                                                                {(a.operation?.targetId || a.operation?.sourceId) ?? 'N/A'}
                                                            </a>
                                                        </Form.Text>
                                                        <br />
                                                        <br />
                                                        {a.operation?.description ?? 'N/A'}
                                                    </Card.Text>
                                                </Card.Body>
                                            </div>
                                        </Card>
                                    )}
                                </div>
                            </Accordion.Body >
                        </Accordion.Item>
                    </Accordion>
                </div >

                {fixtures.map(fixture =>
                    <>
                        {!closedFixture &&
                            <>
                                {fixture.toggle &&
                                    <div className="section" id="fixture">
                                        <Card className={styles.card}>
                                            <Card.Header>
                                                <Title level={2} content={i18n.t('title.fixtures')} />
                                            </Card.Header>
                                            <Card.Body className="cardBody">
                                                <div>
                                                    <div>
                                                        <Form.Label className={styles.formLabel}>
                                                            <strong>
                                                                ID :
                                                            </strong>
                                                        </Form.Label>
                                                        <Form.Text>
                                                            {fixture.fixture.id ?? 'N/A'}
                                                        </Form.Text>
                                                    </div>
                                                    <div>
                                                        <Form.Label className={styles.formLabel}>
                                                            <strong>
                                                                {i18n.t('label.resourcereference')} :
                                                            </strong>
                                                        </Form.Label>
                                                        <Form.Text>
                                                            <a
                                                                href="#fixture"
                                                                onClick={() => {
                                                                    navigateToFixtureEdition(fixture.fixture.id ?? '');
                                                                }}
                                                            >
                                                                {fixture.fixture.resource?.reference ?? 'N/A'}
                                                            </a>
                                                        </Form.Text>
                                                    </div>
                                                    <div>
                                                        <Form.Label className={styles.formLabel}>
                                                            <strong>
                                                                {i18n.t('label.actor')} :
                                                            </strong>
                                                        </Form.Label>
                                                        <Form.Text>
                                                            {fixture.fixture.resource?.display ?? 'N/A'}
                                                        </Form.Text>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                }
                            </>
                        }
                    </>
                )}

                <Button
                    className="button"
                    variant="danger"
                    onClick={() => {
                        if (testScriptId) {
                            lauchTest(testScriptId);
                        }
                    }}
                >
                    {i18n.t('button.launch')}
                </Button>
            </>
        </PandoraPage >
    );
};

export default TestScriptViewer;