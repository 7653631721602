import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Client from 'fhir-kit-client';
import { Bundle, Device, Parameters, TestReport, TestScript } from "fhir/r5";
import i18n from "i18next";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import PandoraPage from '../../components/PandoraPage/PandoraPage';
import { Title } from "@fyrstain/fhir-front-library";
import styles from "./systemsSelection.module.css";

const SystemsSelection: FunctionComponent = () => {

    const clientTooltip = i18n.t('tooltip.clientdescription');
    const serverTooltip = i18n.t('tooltip.serverdescription');

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////

    const [deviceClient, setDeviceClient] = useState("")
    const [deviceServer, setDeviceServer] = useState("");
    const [loading, setLoading] = useState(false);

    /////////////////////////////////////
    //      Constants / ValueSet       //
    /////////////////////////////////////

    const { testScriptId } = useParams();
    const [testScriptName, setTestScriptName] = useState("");
    const [testScriptDescription, setTestScriptDescription] = useState("");
    const [devicesClient, setDevicesClient] = useState([] as Device[]);
    const [devicesServer, setDevicesServer] = useState([] as Device[]);

    /////////////////////////////////////
    //             Client              //
    /////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////////////
    //             Actions             //
    /////////////////////////////////////

    const navigate = useNavigate();

    const toReportDetails = useCallback((id: string) => {
        navigate("/TestReportDetails/" + id);
    }, [navigate]);

    async function launchTest() {
        startTestScript();
    }

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    useEffect(() => {
        loadTestScriptInformations();
        loadPage();
    }, []);

    /**
     * Load the TestScript informations to display in fields.
     */
    async function loadTestScriptInformations() {
        try {
            const response = await fhirClient.request('/TestScript/' + testScriptId, { method: 'GET' });
            const testScript: TestScript = response as TestScript;
            setTestScriptName(testScript.name);
            setTestScriptDescription(testScript.description ?? "undefined");
        } catch (error) {
            console.log(error);
            onError();
        }
        return [];
    }

    /**
     * start the Test script to build the test.
     */
    async function startTestScript() {
        try {
            const bodyParameters = {
                "resourceType": "Parameters",
                "parameter": [
                    {
                        "name": "serverID",
                        "valueString": deviceServer
                    },
                    {
                        "name": "clientID",
                        "valueString": deviceClient
                    }]
            } as Parameters;
            const response = await fhirClient.operation({
                name: '$launchTest',
                resourceType: 'TestScript',
                id: testScriptId,
                method: 'POST',
                input: bodyParameters,
            });
            const report: TestReport = response as TestReport;
            toReportDetails(report.id ?? "");
        } catch (error) {
            console.log(error);
            onError();
        }
    }

    /**
     * Load the page used for select fields.
     */
    async function loadPage() {
        setLoading(true);
        await loadSystemData();
        await loadTestScriptInformations();
        setLoading(false);
    }

    /**
     * Load the Device name to display in select fields.
     */
    async function loadSystemData() {
        try {
            const response = await fhirClient.search({
                resourceType: 'Device',
                searchParams: {
                    "_elements": "displayName,id,type",
                    "type": "http://fyrstain.com/pdt/ValueSet/systemTypes|simulator,http://fyrstain.com/pdt/ValueSet/systemTypes|sut"
                }
            })
            const bundle: Bundle = response as Bundle;
            const devices = bundle.entry?.map(
                entry => entry.resource as Device
            ) ?? [];
            setDevicesClient(devices);
            setDevicesServer(devices);
        } catch (error) {
            console.log(error);
            onError();
        }
    }

    function reset() {
        setLoading(true);
        setDeviceClient("undefined");
        setDeviceServer("undefined");
        setLoading(false);
    }

    /////////////////////////////////////
    //          Page Content           //
    /////////////////////////////////////

    function getOption(device: Device) {
        return <option value={device.id}>
            {device.displayName ?? ""} / {device.type?.map(type => type.coding?.map(coding => coding.display ?? ""))}
        </option>;
    }

    return (
        <PandoraPage titleKey='title.systemsselection' loading={loading} needsLogin={true} >
            <div className={styles.main}>
                <Card className={styles.card}>
                    <Card.Header>
                        <Title level={2} content={i18n.t('title.testinformations')} />
                    </Card.Header>
                    <Card.Body className="cardBody">
                        <div className={styles.form}>
                            <div className={styles.formTextLabel}>
                                <Form.Label className={styles.formLabel}>
                                    <strong className={styles.label}>
                                        ID :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {testScriptId}
                                </Form.Text>
                            </div>
                            <div className={styles.formTextLabel}>
                                <Form.Label className={styles.formLabel}>
                                    <strong className={styles.label}>
                                        {i18n.t('label.name')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {testScriptName}
                                </Form.Text>
                            </div>
                            <div className={styles.formTextLabel}>
                                <Form.Label className={styles.formLabel}>
                                    <strong className={styles.label}>
                                        {i18n.t('label.generaldescription')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {testScriptDescription}
                                </Form.Text>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <Title level={2} content={i18n.t('title.launchtest')} />
                    </Card.Header>
                    <Card.Body className="cardBody">
                        <div className="largeContainer spaceBetweenContainer select">
                            <Form.Select className={styles.formSelect}
                                value={deviceClient}
                                onChange={(e) => {
                                    setDeviceClient(e.target.value);
                                }}
                            >
                                <option value="undefined">
                                    {i18n.t('defaultselectoption.client')}
                                </option>
                                {devicesClient.map(device => getOption(device))}
                            </Form.Select>
                            <button
                                type="button"
                                className="btn btn-secondary btnInfo"
                                data-toggle="tooltip"
                                data-placement="right"
                                title={clientTooltip}
                            >
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                />
                            </button>
                        </div>
                        <div className="largeContainer spaceBetweenContainer select">
                            <Form.Select className={styles.formSelect}
                                value={deviceServer}
                                onChange={(e) => {
                                    setDeviceServer(e.target.value);
                                }}
                            >
                                <option value="undefined">
                                    {i18n.t('defaultselectoption.server')}
                                </option>
                                {devicesServer.map(device => getOption(device))}
                            </Form.Select>
                            <button
                                type="button"
                                className="btn btn-secondary btnInfo"
                                data-toggle="tooltip"
                                data-placement="right"
                                title={serverTooltip}
                            >
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                />
                            </button>
                        </div>
                        <div className={styles.btnContainer}>
                            <Button
                                className="button"
                                variant="danger"
                                onClick={launchTest}
                            >
                                {i18n.t('button.launch')}
                            </Button>
                            <Button
                                className="button"
                                variant="secondary"
                                onClick={reset}
                            >
                                {i18n.t('button.reset')}
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </PandoraPage>
    );
};

export default SystemsSelection;
