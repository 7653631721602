// Library
import { FhirStatus, StatusAlert, Title } from "@fyrstain/fhir-front-library";
// FHIR
import Client from "fhir-kit-client";
import { Bundle, BundleEntry, Composition, Parameters, ParametersParameter } from "fhir/r5";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useEffect, useState } from "react";
// React Bootstrap
import { Card, Form } from "react-bootstrap";
// Components
import PandoraPage from "../../components/PandoraPage/PandoraPage";
// Style
import styles from "./EvaluationReport.module.css";
// React Router
import { useNavigate, useParams } from "react-router-dom";

const EvaluationReport: FunctionComponent = () => {

  ////////////////////////////
  //         State         //
  //////////////////////////

  const [loading, setLoading] = useState<boolean>(true);
  const { evaluationReportId } = useParams();
  const [evaluationReport, setEvaluationReport] = useState({} as Bundle);
  const [rawContent, setRawContent] = useState({})
  const [expressions, setExpressions] = useState<{ expression: string, result: string[] | undefined }[]>([]);
  const [testReport, setTestReport] = useState<{ id: string, name: string } | null>(null);

  ////////////////////////////
  //         Client        //
  //////////////////////////

  const fhirClient = new Client({
    baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
  });

  ////////////////////////////
  //        Actions        //
  //////////////////////////

  const navigate = useNavigate();

  const onError = useCallback(() => {
    navigate("/Error");
  }, [navigate]);

  useEffect(() => {
    loadEvaluationReport();
  }, []);

  /**
   * Load the evaluation report
   * 
   * @returns {Promise<void>}
   * 
   */
  async function loadEvaluationReport(): Promise<void> {
    setLoading(true);
    try {
      const response = await fhirClient.read({
        resourceType: 'Bundle',
        id: evaluationReportId ?? ''
      })
      const evaluationReport: Bundle = response as Bundle;
      if (evaluationReport.entry) {
        const type = (evaluationReport.entry[0].resource as Composition).type.coding?.filter(c => c.system === "http://isis.com/ValueSet/VS-CompositionSectionCode").map(c => c.code)[0] ?? "";
        if (type === "validation-report") {
          onValidationReport(evaluationReport.id ?? "");
        }
        setRawContent(JSON.parse((evaluationReport.entry[1].resource as Parameters).parameter?.findLast(p => p.name === "resource")?.valueString ?? "{}"));
      }
      setEvaluationReport(evaluationReport);
      setExpressions(getExpressions(evaluationReport));
      try {
        const report = await getTestReport(evaluationReport);
        setTestReport(report);
      } catch (error) {
        setTestReport(null);
      }
    } catch (error) {
      onError();
    } finally {
      setLoading(false);
    }
  }

  const onValidationReport = useCallback((id: string) => {
    navigate("/ValidationReport/" + id);
  }, [navigate]);

  /**
  * Function to get the expressions and results from the evaluation report
  * 
  * @param evaluationReport 
  * 
  */
  function getExpressions(evaluationReport: Bundle): { expression: string, result: string[] }[] {
    const expressionsArray: { expression: string, result: string[] }[] = [];
    let expression: string = '';
    let results: string[] = [];
    evaluationReport.entry?.forEach(entry => {
      if ('Parameters' === entry.resource?.resourceType) {
        const parameters: Parameters = entry.resource as unknown as Parameters;
        parameters.parameter?.forEach(parameter => {
          if (parameter.name === 'expression') {
            if (expression) {
              expressionsArray.push({ expression, result: results });
              results = [];
            }
            expression = parameter.valueString || 'N/A';
          } else if (parameter.name === 'result') {
            const resultParts = parameter?.part?.filter(part => part.name === 'result');
            if (resultParts && resultParts.length > 0) {
              results = results.concat(resultParts.map(getValue).filter((value): value is string => value !== undefined));
            }
          }
        });
      }
    });
    if (expression) {
      expressionsArray.push({ expression, result: results });
    }
    return expressionsArray;
  }

  /**
    * Get test report to populate the field.
    * 
    * @param bundle    the bundle to the Evaluation report.
    */
  async function getTestReport(bundle: Bundle) {
    const evaluationReportId = bundle.id || '';
    const response = await fhirClient.search({
      resourceType: 'TestReport',
      searchParams: {
        _elements: '_id,name',
        _filter: 'report-test-link sw "Bundle/' + evaluationReportId + '" or report-setup-link sw "Bundle/' + evaluationReportId + '"'
      }
    });
    if (response.entry && response.entry.length > 0) {
      const testReport = response.entry.find((entry: BundleEntry) => entry.resource?.resourceType === 'TestReport');
      if (testReport && testReport.resource) {
        return {
          id: testReport.resource.id,
          name: testReport.resource.name
        };
      }
    }
    throw new Error('Ce rapport d\'évaluation n\'est pas issu d\'un test');
  }

  /**
   * Function which provides the different types of attributes which allow the display of the result of expressions with FhirPath
   * 
   * @param parameter 
   *  
   */
  function getValue(parameter: ParametersParameter | undefined): string | undefined {
    if (!parameter) return undefined;
    switch (true) {
      case parameter.valueString !== undefined:
        return parameter.valueString;
      case parameter.valueBoolean !== undefined:
        return parameter.valueBoolean?.toString();
      case parameter.valueInteger !== undefined:
        return parameter.valueInteger?.toString();
      case parameter.valueDecimal !== undefined:
        return parameter.valueDecimal?.toString();
      case parameter.valueUri !== undefined:
        return parameter.valueUri;
      case parameter.valueDate !== undefined:
        return parameter.valueDate;
      case parameter.valueDateTime !== undefined:
        return parameter.valueDateTime;
      case parameter.valueTime !== undefined:
        return parameter.valueTime;
      case parameter.valueCode !== undefined:
        return parameter.valueCode;
      case parameter.valueBase64Binary !== undefined:
        return parameter.valueBase64Binary;
      case parameter.valueCoding !== undefined:
        return parameter.valueCoding?.system && parameter.valueCoding?.code
          ? `System : ${parameter.valueCoding.system} - Code : ${parameter.valueCoding.code}`
          : undefined;
      case parameter.valueQuantity !== undefined:
        return parameter.valueQuantity?.value && parameter.valueQuantity?.unit
          ? `${parameter.valueQuantity.value} ${parameter.valueQuantity.unit}`
          : undefined;
      case parameter.valueReference !== undefined:
        return parameter.valueReference?.reference || undefined;
      case parameter.resource !== undefined:
        return JSON.stringify(parameter.resource, null, 2) || undefined;
      default:
        return undefined;
    }
  }

  ////////////////////////////
  //        Content        //
  //////////////////////////

  const renderResult = (result: string | undefined, resultIndex: number | undefined) => {
    let content;
    if (Array.isArray(result)) {
      content = result.map((item, index) => (
        <div key={index}>
          <pre>{JSON.stringify(item, null, 2)}</pre>
        </div>
      ));
    } else if (typeof result === 'string' && (result.startsWith('{') || result.startsWith('['))) {
      content = (
        <>
          <pre>{result}</pre>
          <div className={styles.separator} />
          <br />
        </>
      );
    } else {
      content = <Form.Text className={result !== undefined && result !== '' ? '' : 'text-danger'}>
        {result !== undefined && result !== '' ? result : i18n.t('errormessage.notfound')}
      </Form.Text>;
    }
    return <div key={resultIndex}>{content}</div>;
  };

  return (
    <PandoraPage
      titleKey='title.evaluationreport'
      loading={loading}
      needsLogin={false}
    >
      <>
        <Card>
          <Card.Header>
            <Title
              level={2}
              content={'Informations'}
            />
          </Card.Header>
          <Card.Body className="cardBody">
            <div className={styles.formTextLabel}>
              <Form.Label>
                <strong className={styles.label}>
                  ID :
                </strong>
              </Form.Label>
              <Form.Text>
                {evaluationReport.id ? evaluationReport.id : 'N/A'}
              </Form.Text>
            </div>
            <div className={styles.formTextLabel}>
              <Form.Label>
                <strong className={styles.label}>
                  Date :
                </strong>
              </Form.Label>
              <Form.Text>
                {evaluationReport.meta?.lastUpdated ? evaluationReport.meta?.lastUpdated : 'N/A'}
              </Form.Text>
            </div>
            <div className={styles.formTextLabel}>
              <Form.Label>
                <strong className={styles.label}>
                  Test ?
                </strong>
              </Form.Label>
              <Form.Text className={testReport ? '' : 'text-danger'}>
                {testReport ? `${testReport.id} / ${testReport.name}` : i18n.t('errormessage.notlinkedevaluation')}
              </Form.Text>
            </div>
          </Card.Body>
        </Card>
        <Card className={styles.cardContainer}>
          <Card.Header>
            <Title level={2} content={i18n.t('title.results')} />
          </Card.Header>
          <Card.Body>
            {expressions.map((expression, index) => (
              <StatusAlert
                key={index}
                status={expression.result?.length ? FhirStatus.successful : FhirStatus.error}
              >
                <div>
                  <div className={styles.formTextLabel}>
                    <Form.Label>
                      <strong className={styles.label}>
                        Expression :
                      </strong>
                    </Form.Label>
                    <Form.Text>
                      {expression.expression}
                    </Form.Text>
                  </div>
                  <div className={[styles.formTextLabel, styles.notificationContent, 'flexWrapStart'].join(' ')}>
                    <Form.Label>
                      <strong className={styles.label}>
                        {i18n.t('label.value')} :
                      </strong>
                    </Form.Label>
                    <div>
                      {expression.result?.length ?? 0 > 0 ? expression.result?.map(renderResult) : renderResult(undefined, undefined)}
                    </div>
                  </div>
                </div>
              </StatusAlert>
            ))}
          </Card.Body>
        </Card>
        <Card className={styles.cardContainer}>
          <Card.Header>
            <Title level={2} content={'Raw content'} />
          </Card.Header>
          <Card.Body>
            <textarea
              className="form-control"
              rows={10}
              readOnly
              value={JSON.stringify(rawContent, null, 2)}
            />
          </Card.Body>
        </Card>
      </>
    </PandoraPage >
  );
};

export default EvaluationReport;